<template>
  <div
    class="layout"
    :class="[isMainApp ? 'inMainApp' : 'inApp', 'device-' + deviceType]"
  >
    <slot name="status-bar" />
    <div class="container">
      <slot name="sider" />
      <div class="main">
        <div class="header" v-if="false">
          <slot name="header" />
        </div>
        <div class="content-container">
          <div class="fixed-content"><slot name="fixed-content" /></div>
          <div class="content"><slot /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    isMainApp() {
      // 当前是否是主界面应用
      return this.$tis.isMainApp();
    },
    deviceType() {
      return this.$tis.bus.deviceType;
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
  background-image: url("~@/assets/bg.png");
  &.inApp {
    background-image: url("~@/assets/app/背景.png");
  }
  &.device-pad {
    background-image: url("~@/assets/app/pad-bg.png");
  }
  &.device-mobile {
    background-image: url("~@/assets/app/mobile-bg.png");
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--layout-bg-color);
  height: 100vh;
  color: var(--layout-text-color);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .container {
    flex: 1;
    display: flex;
    .main {
      flex: 1;
      display: flex;
      flex-direction: column;
      .header {
        min-height: 20px;
      }
      .content-container {
        flex: 1 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
      }
      .fixed-content {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .content {
        position: relative;
        flex: 1 0 auto;
        height: 0;
        overflow: scroll;
      }
    }
  }
}
</style>
