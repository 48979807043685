let wsUrl = 'ws://tis207demows.vaiwan.com/websocket'
// let wsUrl = "/websocket";
var ws = null //实现WebSocket
var record = null //多媒体对象，用来处理音频

function init(rec) {
  record = rec
}

//录音对象
var Recorder = function(stream) {
  var context = new AudioContext()
  var audioInput = context.createMediaStreamSource(stream)
  var recorder = context.createScriptProcessor(0, 1, 1)
  var audioData = {
    size: 0, //录音文件长度
    buffer: [], //录音缓存
    clear: function() {
      this.buffer = []
      this.size = 0
    },
    input: function(data) {
      this.buffer.push(new Float32Array(data))
      this.size += data.length
    },
    to16BitPCM: function(input) {
      var dataLength = input.length * (16 / 8)
      var dataBuffer = new ArrayBuffer(dataLength)
      var dataView = new DataView(dataBuffer)
      var offset = 0
      for (var i = 0; i < input.length; i++, offset += 2) {
        var s = Math.max(-1, Math.min(1, input[i]))
        dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
      }
      return new Blob([dataView])
    },
    to16kHz: function() {
      //var data = new Float32Array(buffer)
      var data = new Float32Array(this.size)
      var offset = 0
      for (var i = 0; i < this.buffer.length; i++) {
        data.set(this.buffer[i], offset)
        offset += this.buffer[i].length
      }
      var fitCount = Math.round(data.length * (16000 / 44100))
      var newData = new Float32Array(fitCount)
      var springFactor = (data.length - 1) / (fitCount - 1)
      newData[0] = data[0]
      for (let i = 1; i < fitCount - 1; i++) {
        var tmp = i * springFactor
        var before = Math.floor(tmp).toFixed()
        var after = Math.ceil(tmp).toFixed()
        var atPoint = tmp - before
        newData[i] = data[before] + (data[after] - data[before]) * atPoint
      }
      newData[fitCount - 1] = data[data.length - 1]
      return newData
    },
    encodeXunFeiPCM: function() {
      return this.to16BitPCM(this.to16kHz())
    },
  }

  var sendData = function() {
    var reader = new FileReader()
    reader.onload = e => {
      var outbuffer = e.target.result
      var arr = new Int8Array(outbuffer)
      if (arr.length > 0) {
        var tmparr = new Int8Array(1024)
        var j = 0
        for (var i = 0; i < arr.byteLength; i++) {
          tmparr[j++] = arr[i]
          if ((i + 1) % 1024 == 0) {
            sendFrameData(tmparr)
            if (arr.byteLength - i - 1 >= 1024) {
              tmparr = new Int8Array(1024)
            } else {
              tmparr = new Int8Array(arr.byteLength - i - 1)
            }
            j = 0
          }
          if (i + 1 == arr.byteLength && (i + 1) % 1024 != 0) {
            sendFrameData(tmparr)
          }
        }
      }
    }
    reader.readAsArrayBuffer(audioData.encodeXunFeiPCM())
    audioData.clear() //每次发送完成则清理掉旧数据
  }

  var sendStartCMD = function() {
    var bf = new ArrayBuffer(4)
    var dataView = new DataView(bf)
    var send_EDFH = 0xfd
    var eEDCmd = 0x20
    var send_package = []
    send_package[0] = send_EDFH
    send_package[1] = 0x00
    send_package[2] = 0x01
    send_package[3] = eEDCmd
    for (var j = 0; j < 4; j++) {
      dataView.setUint8(j, send_package[j])
    }
    ws.send(dataView)
  }

  var sendFrameData = function(data) {
    var bf = new ArrayBuffer(data.byteLength + 4)
    var dataView = new DataView(bf)
    var send_EDFH = 0xfd
    var eEDCmd = 0x21
    var send_package = []
    var length = data.byteLength + 1
    send_package[0] = send_EDFH
    send_package[1] = length / 256
    send_package[2] = length % 256
    send_package[3] = eEDCmd
    for (var j = 0; j < 4; j++) {
      dataView.setUint8(j, send_package[j])
    }
    for (var k = 0; k < data.byteLength; k++) {
      dataView.setUint8(k + 4, data[k])
    }
    ws.send(dataView)
  }

  this.start = function() {
    sendStartCMD()
    audioInput.connect(recorder)
    recorder.connect(context.destination)
  }

  this.stop = function() {
    recorder.disconnect()
  }

  this.getBlob = function() {
    return audioData.encodePCM()
  }

  this.clear = function() {
    audioData.clear()
  }

  recorder.onaudioprocess = function(e) {
    var inputBuffer = e.inputBuffer.getChannelData(0)
    audioData.input(inputBuffer)
    sendData()
  }
}

function ab2str(u, f) {
  var b = new Blob([u])
  var r = new FileReader()
  r.readAsText(b, 'utf-8')
  r.onload = function() {
    if (f) f.call(null, r.result)
  }
}

/*
 * WebSocket
 */
function useWebSocket() {
  ws = new WebSocket(wsUrl)
  ws.binaryType = 'arraybuffer' //传输的是 ArrayBuffer 类型的数据
  ws.onopen = function() {
    console.log('握手成功')
    if (ws.readyState == 1) {
      //ws进入连接状态，则每隔500毫秒发送一包数据
      record.start()
    }
  }

  ws.onmessage = function(msg) {
    console.info(msg)
    ab2str(msg.data.slice(4, msg.data.length), function(str) {
      _callback && _callback(str)
    })
  }

  ws.onerror = function(err) {
    console.info(err)
  }
}
let _callback = null
/*
 * 开始对讲
 */
function start(callback) {
  _callback = callback
  navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
  if (!navigator.getUserMedia) {
    alert('浏览器不支持音频输入')
  } else {
    navigator.getUserMedia(
      {
        audio: true,
      },
      function(mediaStream) {
        init(new Recorder(mediaStream))
        console.log('开始对讲')
        useWebSocket()
      },
      function(error) {
        console.log(error)
        switch (error.message || error.name) {
          case 'PERMISSION_DENIED':
          case 'PermissionDeniedError':
            console.info('用户拒绝提供信息。')
            break
          case 'NOT_SUPPORTED_ERROR':
          case 'NotSupportedError':
            console.info('浏览器不支持硬件设备。')
            break
          case 'MANDATORY_UNSATISFIED_ERROR':
          case 'MandatoryUnsatisfiedError':
            console.info('无法发现指定的硬件设备。')
            break
          default:
            console.info('无法打开麦克风。异常信息:' + (error.code || error.name))
            break
        }
      }
    )
  }
}

export default {
  close() {
    if (ws) {
      ws.close()
      record.stop()
      console.log('关闭对讲以及WebSocket')
    }
  },
  start,
}
