// 地址栏输入：chrome://flags/#unsafely-treat-insecure-origin-as-secure

//import syberh from "@syberos/jsbridge";
// http://192.168.1.103:7007/api/aisound/voice2str/voice_file/1634970829601_tts_123.wav
export default {
  data() {
    return {
      voiceValue: null,
      recordTimeInterval: null,
      recordFlg: null,
      device: null,
      voiceTime: 1,
      resdata: null,
      voiceSrc: null,
      getIatoneType: 2, // 1 命令词 2 自由说 3 实时流
      commandList: null,
    }
  },
  mounted() {
    // this.$tis.voice.getVoice({time: '20210720', string: '你好'})
    this.getBnf()
  },
  methods: {
    getBnf() {
      this.$tis.voice
        .getBnf()
        .then(res => {
          this.commandList = (res || '').split(/\|/g)
        })
        .catch(err => {
          console.log(err)
        })
    },
    recordHandle() {
      this.recordFlg = !this.recordFlg
      this.voiceValue = null
      if (this.recordFlg) {
        this.recordStart()
      } else {
        this.recordEnd()
      }
    },
    // 开始录音
    async recordStart() {
      try {
        this.resdata = '正在录音...'
        console.log('开始录音')
        await this.$tis.voice.recordStart()
        // 开始录音
        this.recordTimeInterval = setInterval(() => {
          this.voiceTime = (parseFloat(this.voiceTime) || 1) + 1
          console.log('setInterval this.voiceTime:', this.voiceTime)
          if (this.voiceTime.toFixed(1) >= 10) {
            // 结束录音
            clearInterval(this.recordTimeInterval)
            this.recordEnd()
          }
        }, 1000)
      } catch (error) {
        console.log(error)
        this.recordFlg = false
        this.resdata = '录音失败！'
        // error.message && this.$message.error('录音失败 ' + error.message);
        clearInterval(this.recordTimeInterval)
      }
    },
    // 结束录音并且发送给后台
    async recordEnd() {
      console.log('录音结束')
      clearInterval(this.recordTimeInterval)
      this.resdata = '正在转换...'
      let recordItem = await this.$tis.voice.recordEnd()
      console.log(recordItem)
      let formdata = new FormData()
      formdata.append('file', recordItem)
      console.log('当前模式', this.$tis.bus.iatoneType)
      try {
        let res = await this.$tis.voice.voide2Str(formdata, this.$tis.bus.iatoneType)
        this.resordStopHandle(res)
        this.voiceTime = 1
      } catch (error) {
        this.resdata = '我不知道你在说什么'
        this.$message.error(this.resdata)
      }
    },
    // 处理识别结果
    resordStopHandle(res) {
      console.log(116, res)
      let result = res.data.data
      if (!result) {
        this.resdata = '无法识别命令'
        this.$message.error(this.resdata)
      } else if (this.commandList.includes(result)) {
        // 命令
        this.$message.success(`执行命令：${result}`)
        this.voiceValue = result
      } else {
        // 自由说
        this.voiceValue = result
      }
    },
  },
}